.rbc-event {
    background-color: #545454 !important;
}

.rbc-show-more {
    color: #545454 !important;
}

.rbc-month-view {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rbc-today {
    background-color: #d7d7d7 !important;
}
